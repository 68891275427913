import type { Config } from '@grandvisionhq/shipping'

export const getDeliveryIndicatorConfig = (): Config => ({
  inventoryChannels: {
    '421f01a8-116b-481e-81ec-8ecd25cbefbe': {
      // online-store TEST
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    '5cc11c26-14ba-4242-babc-4867557b2454': {
      // external-warehouse TEST
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
    'd3371e7f-7dd1-43c6-972c-d2f592abf962': {
      // online-store ACCT
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    '237d2364-9f73-4c54-9b58-cef883eb5297': {
      // external-warehouse ACCT
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
    '4600508c-622c-47b1-9eab-c07cdaf5d482': {
      // online-store PROD
      expectedDeliveryDays: {
        onStock: '1-3',
        outOfStock: '8-10',
      },
    },
    '4d1e77c5-c898-4b3b-8458-143dabe9bb63': {
      // external-warehouse PROD
      expectedDeliveryDays: {
        onStock: '14',
        outOfStock: '14',
      },
    },
  },
  missingInventoryChannelStrategy: {
    expectedDeliveryDays: '14+',
  },
})
